import * as React from "react"
import { graphql } from "gatsby"

import { TypeBlogArticle, TypeCategory } from "../../app/createPages"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo/seo"
import Divider from "../../components/divider/divider"
import OutlinedButton from "../../components/buttons/outlinedButton"
import ArticleGrid from "../../components/articles/articleGrid"
import SocialButtonsBar from "../../components/socials/socialButtonsBar"
import Modal from "../../components/modal/modal"
import TextButton from "../../components/buttons/textButton"

const description =
  "Stay informed and get all the latest news, strategies, and digital developments."

type TypeData = {
  allSanityCategory: {
    edges: {
      node: TypeCategory
    }[]
  }
  allSanityPost: {
    edges: {
      node: TypeBlogArticle
    }[]
  }
}

type BlogPageProps = {
  data: TypeData
}

const BlogPage = ({ data }: BlogPageProps) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const handleTopicModalClose = () => setModalOpen(!modalOpen)

  const categories = data.allSanityCategory.edges.map(edge => edge.node)
  const articles = data.allSanityPost.edges.map(edge => edge.node)

  return (
    <Layout>
      <Seo title="Blog" description={description} />
      <section className="container mt-5">
        <div className="mw-800">
          <h4>Blog</h4>
          <p className="body1">{description}</p>
        </div>
        <Divider />
        <div className="bg-white pt-2 pb-2 row justify-space-between">
          <SocialButtonsBar />
        </div>
        <Divider />
        <div className="bg-white pt-2 pb-2">
          <h1 className="caption">Browse By</h1>
          <div className="display-f scrollbar-hidden mt-2 mb-2">
            <div className="mr-2">
              <OutlinedButton
                label="Topic"
                onBtnClick={handleTopicModalClose}
              />
            </div>
          </div>
        </div>
        <Divider />
        <ArticleGrid articles={articles} />
      </section>
      <Modal
        open={modalOpen}
        handleClose={handleTopicModalClose}
        title="Browse by topic"
      >
        {categories.map(category => (
          <div key={category.title}>
            <TextButton
              label={category.title}
              href={`/resources/blog/${category.slug.current}`}
            />
          </div>
        ))}
      </Modal>
    </Layout>
  )
}

export const blogQuery = graphql`
  query {
    allSanityCategory {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      filter: { slug: { current: { ne: null } } }
    ) {
      edges {
        node {
          title
          excerpt
          id
          mainImage {
            asset {
              gatsbyImageData(aspectRatio: 1.78)
            }
          }
          slug {
            current
          }
          publishedAt
          author {
            name
          }
          category {
            title
            slug {
              current
            }
          }
          tags {
            title
          }
        }
      }
    }
  }
`

export default BlogPage
